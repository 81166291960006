<template>
    <div v-show="active" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
        <div class="items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Edit team
            </h3>
            <div class="mt-4">
              <p class="text-sm leading-5 text-gray-500">
                Please contact support@sendx.io to edit a team.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click.prevent="modalCancelClicked(false)" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Okay
            </button>
            </span>
        </div>
      </div>
    </div>
  
  </template>
   
<script setup>

const props = defineProps({
    active: {
        type: Boolean,
        default: false
    }
});

definePageMeta({
  colorMode: "light",
});

const emit = defineEmits(['cancelModalClicked']);

const modalCancelClicked = (val) => {
    emit("cancelModalClicked", val)
}
</script>
  